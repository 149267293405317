/* eslint-disable import-helpers/order-imports */
import React from 'react';

import ArticlesOverviewTopMain from '@sections/ArticlesOverviewTopMain';
import ArticlesOverviewTopCategory from '@sections/ArticlesOverviewTopCategory';
import Hero from '@sections/Hero';
import Form from '@sections/Form';

import {
  getLatestArticle,
  getHeroInfo,
} from '@utils';
import { graphql } from 'gatsby';

import '../styles/templates/articleOverview.css';

type ArticleProps = {
  contentful_id: string,
  title: string,
};

type CategoryProps = {
  contentful_id: string,
  title: string,
  type: string,
  slug: string,
};

type FormProps = {
  contentful_id: string,
};

type HeroProps = {
  contentful_id: string,
};

type AllArticlesProps = {
  edges: Array<{
    node: ArticleProps,
  }>
};

type ArtOverviewProps = {
  articleForHeroSection: CategoryProps;
  type: string,
  form: FormProps,
  heroImage: HeroProps,
  useFilter: boolean,
  categoriesList: CategoryProps[],
};

type ArticleOverviewProps = {
  pageContext: {
    isCategory: boolean,
    currentPage: number,
    mainLink: string,
    categorySelectedId: string,
    numPages: number,
  };
  data: {
    allArticles: AllArticlesProps,
    categorySelected: CategoryProps,
    articlesSelected: ArticleProps,
    articleOverview: ArtOverviewProps,
  };
};

const ArticleOverview: React.FC<ArticleOverviewProps> = ({
  pageContext,
  data,
}) => {
  const { 
    isCategory,
    currentPage,
    numPages,
  } = pageContext;

  const {
    allArticles,
    categorySelected,
    articlesSelected,
    articleOverview,
  } = data;

  const {
    type,
    heroImage,
    form,
    useFilter,
    articleForHeroSection,
  } = articleOverview;

  let articleShown = null
  const latestArticle = getLatestArticle(allArticles, type);

  let dummy = false
  if(articleForHeroSection === null){
    articleShown = latestArticle
    dummy = true
  }else{
    articleShown = articleForHeroSection
  }

  const heroInfo = getHeroInfo(heroImage, articleShown, undefined, undefined, useFilter, dummy);

  return (
    <>
      {type !== 'Blog' && <div className="article-overview--main">
        <Hero
          extra={heroInfo.extra}
          item={heroInfo.item}
        />
      </div>}
      {
        !isCategory 
          ? (
            <ArticlesOverviewTopMain
              allArticles={allArticles}
              articleOverview={articleOverview}
            >
              <Form item={form} />
            </ArticlesOverviewTopMain>
          ) : (
            <ArticlesOverviewTopCategory
              articleOverview={articleOverview}
              articlesSelected={articlesSelected}
              categorySelected={categorySelected}
              currentPage={currentPage}
              numPages={numPages}
            >
              <Form item={form} />
            </ArticlesOverviewTopCategory>
          )
      }
    </>
  );
};

export default ArticleOverview;

export const overviewQuery = graphql`
  query QUERY_ARTICLE_OVERVIEW(
    $articleOverviewId: String!
    $categorySelectedId: String!
    $articlesSelectedId: [String!]!
  ) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
    seo: contentfulArticleOverview(contentful_id: { eq: $articleOverviewId }) {
      ...ArticleOverviewSeo
    }

    articleOverview: contentfulArticleOverview(
      contentful_id: { eq: $articleOverviewId }
    ) {
      ...ArticleOverviewFull
    }

    categorySelected: contentfulCategory(
      contentful_id: { eq: $categorySelectedId }
    ) {
      ...Category
    }

    articlesSelected: allContentfulArticle(
      filter: { contentful_id: { in: $articlesSelectedId } }
    ) {
      edges {
        node {
          ...ArticleWithImage
        }
      }
    }

    allArticles: allContentfulArticle(
      filter: { slug: { regex: "/^((?!placeholder-not-erase).)*$/" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...ArticleWithImage
        }
      }
    }

    allCategories: allContentfulCategory(
      sort: { order: DESC, fields: updatedAt }
    ) {
      edges {
        node {
          ...Category
        }
      }
    }
  }
`;
