import React from 'react';

import Pagination from '@elements/Pagination';
import ArticlesGrid from '@sections/ArticlesGrid';
import {
  getDropDownListfrCategories,
  reorderArticles,
} from '@utils';

type ArticleProps = {
  contentful_id: string,
  title: string,
};

type CategoryProps = {
  contentful_id: string,
  title: string,
  type: string,
  slug: string,
  displayName: string,
};

type ArtOverviewProps = {
  type: string,
  gridTitle: string,
  gridSubtitle: string,
  slug: string,
  categoriesList: CategoryProps[],
};

type ArticlesOverviewTopCategoryProps = {
  categorySelected: CategoryProps,
  articlesSelected: ArticleProps[],
  articleOverview: ArtOverviewProps,
  currentPage: number,
  numPages: number,
  children?: React.ReactNode,
};

const ArticlesOverviewTopCategory = ({
  categorySelected,
  articlesSelected,
  articleOverview,
  currentPage,
  numPages,
  children,
}: ArticlesOverviewTopCategoryProps): JSX.Element => {
  const {
    categoriesList,
    gridTitle,
    gridSubtitle,
    slug,
  } = articleOverview;

  const articlesSelect = reorderArticles(articlesSelected);
  const maxArticles = articlesSelect.length;

  const item = {
    title: gridTitle,
    subtitle: gridSubtitle,
    classes: "articles-grid--overview-main articles-grid--top-blog",
    maxArticles,
  };

  const dropDownList = getDropDownListfrCategories(categoriesList, true);
  const dropDownSelected = dropDownList
    .find(({ displayName }) => displayName === categorySelected.displayName); 
  const extra = {
    useDropdownButton: true,
    articlesList: articlesSelect.slice(0,6),
    dropDownList,
    dropDownSelected,
  };

  return (
    <>
      <ArticlesGrid
        extra={extra}
        item={item}
      />
      <ArticlesGrid
        extra={{ articlesList: articlesSelect.slice(6) }}
        item={{ maxArticles: 6 }}
      />
      <Pagination
        currentPage={currentPage}
        mainLink={`/${slug}/${categorySelected.slug}/`}
        paginationId={categorySelected.contentful_id}
        totalPage={numPages}
      />
      {children}
    </>
  );
};

export default ArticlesOverviewTopCategory;
