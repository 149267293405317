import React, { useState } from 'react';

import Pagination from '@elements/Pagination';
import ArticlesGrid from '@sections/ArticlesGrid';
import {
  joinItems,
  splitList,
  getDropDownListfrCategories,
} from '@utils';
import { navigate } from 'gatsby';

type ArticleProps = {
  contentful_id: string,
  title: string,
  type: string,
};

type CategoryProps = {
  contentful_id: string,
  title: string,
  type: string,
  slug: string,
  displayName: string,
};

type FormProps = {
  contentful_id: string,
};

type AllArticlesProps = {
  edges: Array<{
    node: ArticleProps,
  }>
};

type ArtOverviewProps = {
  contentful_id: string;
  type: string;
  categoriesList: CategoryProps[];
  gridTitle: string;
  gridSubtitle: string;
  form: FormProps;
};

type ArticlesOverviewTopMainProps = {
  allArticles: AllArticlesProps,
  articleOverview: ArtOverviewProps,
  children?: React.ReactNode,
};

const ArticlesOverviewTopMain = ({
  allArticles,
  articleOverview,
  children,
}: ArticlesOverviewTopMainProps): JSX.Element => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    contentful_id,
    type,
    categoriesList,
    gridTitle,
    gridSubtitle,
  } = articleOverview;

  const articlesByType = allArticles.edges
    .filter(({ node }) => node.type === type)
    .map(({ node }) => node );
  const articlesList = joinItems(articlesByType, 12, 11);
  const totalPage = articlesList.length;
  const initArticles = splitList(articlesList[0], 5);

  const [page, setPage] = useState(1);
  const [articlesSelect, setArticlesSelect] = useState(initArticles);

  const handleChange = ({ numPage }) => {
    const newArticlesList = splitList(
      articlesList[numPage - 1],
      numPage !== 1 ? 6 : 5,
    );
    setPage(numPage);
    setArticlesSelect(newArticlesList);
    navigate("#articles");
  };

  const itemTop = {
    title: gridTitle,
    subtitle: gridSubtitle,
    classes: "articles-grid--overview-main articles-grid--top-blog",
    firstisBig: page === 1,
    htmlId: "articles"
  };

  const extraTop = {
    useDropdownButton: type === "Blog",
    articlesList: articlesSelect[0],
    dropDownList: getDropDownListfrCategories(categoriesList, true),
    dropDownSelected: { title: "Choose a category" },
  };

  const extraBottom = {
    articlesList: articlesSelect[1],
  };

  return (
    <>
      <ArticlesGrid
        extra={extraTop}
        item={itemTop}
      />
      <ArticlesGrid
        extra={extraBottom}
        item={{ maxArticles: 6 }}
      />
      <Pagination
        currentPage={page}
        handleClick={handleChange}
        paginationId={contentful_id}
        totalPage={totalPage}
      />  
      {children}
    </>
  );
};

export default ArticlesOverviewTopMain;
